import $ from 'jquery'
const $doc = $(document)
let state = {
    curr_page: 1,
    category: 'all',
    taxonomy: 'all',
    perPage: 2 // standardized posts_per_page count to reliably use paging
}

function filterarchive() {
    const archiveListWrapper = $('.archive__list-wrapper')
    const archiveList = $('.archive__list')
    const taxonomy = $('.archive__filter .js-filter-btn.is-active').attr('id');
    const category = $('.archive__filter .js-filter-btn.is-active').data('cat');

    state.taxonomy = taxonomy
    state.category = category
    state.curr_page = 1

    // console.log(taxonomy)
    // console.log(category)

    $.ajax({
        url : ajax_object.ajax_url,
        type : 'POST',
        data : {
            taxonomy,
            category,
            action:'ajaxfilter'
        },
        beforeSend : function(xhr){
            archiveList.empty();
            archiveListWrapper.addClass('loading');
            $('.js-load-more-archive').hide();
        },
        success : function( data ){
            var $data = $(data);
        
            if ($data.length) {
                archiveList.html($data);
                archiveListWrapper.removeClass('loading');
                $data = $data.filter( function() { return this.nodeName == 'LI' } )
                // console.log($data)
                if($data.length < state.perPage || $data.length === -1) {
                    $('.js-load-more-archive').hide();
                } else {
                    $('.js-load-more-archive').show();
                }
            } else {
                archiveList.append('<h4 class="u-text-center u-color-redorange" style="width: 100%">No articles matching search criteria found!</h4>');
                $('.js-load-more-archive').hide();
                archiveListWrapper.removeClass('loading');
            }
        }
    })
}

function loadMorearchive() {
    const archiveListWrapper = $('.archive__list-wrapper')
    const archiveList = $('.archive__list')
    const category = $('.archive__list-wrapper').data('category');
    const taxonomy = $('.archive__list-wrapper').data('taxonomy');
    const curr_page = state.curr_page + 1;

    state.category = category
    state.curr_page = curr_page
    state.taxonomy = taxonomy

    // console.log('cat: ' + category)
    // console.log('tax: ' + taxonomy)
    // console.log('curr page: ' + curr_page)

    $.ajax({
        url : ajax_object.ajax_url,
        type : 'POST',
        data : {
            category,
            taxonomy,
            curr_page,
            action:'loadmorearchive'
        },
        beforeSend : function(xhr){
            archiveListWrapper.addClass('loading');
            $('.js-load-more-archive').hide();
        },
        success : function( data ){

            var $data = $(data);
        
            if ($data.length) {
                archiveList.append($data);
                archiveListWrapper.removeClass('loading');
                $data = $data.filter( function() { return this.nodeName == 'LI' } )
                // console.log($data)
                if($data.length < state.perPage || $data.length === -1) {
                    $('.js-load-more-archive').hide();
                } else {
                    $('.js-load-more-archive').show();
                }
            } else {
                archiveList.append('<h4 class="u-text-center u-color-redorange" style="width: 100%">No more articles matching search criteria found!</h4>');
                $('.js-load-more-archive').hide();
                archiveListWrapper.removeClass('loading');
            }
        },
        error : function(error){ console.log(error) }
    })
}


export default function initFilterPosts(){ 
    $doc.on('click', '.archive__filter .js-filter-btn', function(event) {
        $('.archive__filter .js-filter-btn').removeClass('is-active')
        $(this).addClass('is-active')
        filterarchive() 
    })
    $(document).on('click', '.js-load-more-archive', loadMorearchive )
}

